<script setup lang="ts">
const news = await getNews()
</script>

<template>
  <section v-if="news" class="section section--news">
    <div
      class="section__wrapper"
      :class="{ 'section__wrapper--compact': news.length < 3 }"
    >
      <h2 class="news__title text-44 text-bold title-mb">Новости отрасли</h2>
      <div class="news__container">
        <Swiper
          :modules="[SwiperPagination]"
          :slides-per-view="1"
          :space-between="20"
          pagination
          :loop="false"
          :breakpoints="{
            768: {
              slidesPerView: news.length > 2 ? 3 : 1,
            },
            575: {
              slidesPerView: 2,
            },
          }"
        >
          <SwiperSlide
            v-for="(item, key) of news"
            :key="key"
            class="news__card"
          >
            <a
              class="news__photo-wrapper"
              :href="item.attributes.link"
              target="_blank"
            >
              <img
                :src="
                  useStrapiMediaUrl(
                    item.attributes.image.data.attributes.formats.large.url,
                  )
                "
                alt=""
                class="news__photo"
              />
            </a>
            <div class="news__content">
              <h3
                class="news__content_title text-16 text-bold"
                v-html="item.attributes.title"
              />
              <p
                class="news__content_excerpt text-16"
                v-html="item.attributes.description"
              />
            </div>
            <a
              :href="item.attributes.link"
              class="news__link text-16"
              target="_blank"
            >
              Подробнее
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section--news {
  border-radius: 0;
  padding-bottom: 70px;
  margin-bottom: 70px;

  @include md {
    margin-bottom: 120px;
  }
  @include sm {
    margin-bottom: 100px;
  }

  .news {
    &__container {
      position: relative;
    }
    &__card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      gap: 30px;
      height: auto;
    }
    &__photo-wrapper {
      display: block;
      width: 100%;
      aspect-ratio: 4/3;
      border-radius: 10px;
      overflow: hidden;
    }
    &__photo {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
    &__content {
      &_title {
        margin-bottom: 0.85em;
      }
      &_excerpt {
      }
    }
    &__link {
      margin-top: auto;
      text-decoration: underline;
    }
  }

  .swiper {
    padding-bottom: 60px;

    @include sm {
      padding-bottom: 30px;
    }
  }
}

.section__wrapper--compact {
  display: flex;
  justify-content: space-between;
  align-items: start;

  @include md {
    display: block;
  }

  .news__title {
    position: sticky;
    top: 2vh;
  }

  .news__container {
    width: 50%;

    @include md {
      width: 100%;
    }
  }
}
</style>
